import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'
import { getFamousPeople } from '../../_helpers/cardHelper'
import { useTranslation } from 'react-i18next'


export const FamousAvatars = ({ profileType }) => {
  
  const { t } = useTranslation()
  
  return (<>
    <Box sx={{ float: 'right', width: { sm: '40%' } }}>
      {getFamousPeople(profileType).map(({ avatar, name, slug }) =>
        <Box component='img' src={avatar} alt={name} key={slug} sx={{ width: '33%' }} />,
      )}
    </Box>
    <Typography variant='h3' color='tertiary.dark' fontSize='1.5rem' fontWeight={800} mt={4} mb={2}>{t('cards.famous.title')}</Typography>
    <Typography variant='body1' fontWeight={200} lineHeight={2} mb={2}>
      {t(`cards.famous.${profileType}`)}
    </Typography>
  </>)
}

FamousAvatars.propTypes = {
  profileType: PropTypes.string.isRequired,
}

export default FamousAvatars
