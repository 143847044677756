import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Input, InputAdornment, FormControl, IconButton } from '@mui/material'
import { AddCircleOutline } from '@mui/icons-material'
import { ONE_ONE_QUESTION_COLLECTION, ONE_ONE_TOPIC_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import { getDoc } from 'firebase/firestore'
import useAuth from '../../hooks/useAuth'
import useFirestore from '../../hooks/useFirestore'


export const NewQuestionForm = ({ onComplete, order }) => {
  
  const profile = useAuth().getProfile()
  const topicHooks = useFirestore(ONE_ONE_TOPIC_COLLECTION)
  const topics = topicHooks.getDocs()
  const userHooks = useFirestore(USER_COLLECTION)
  const questionHooks = useFirestore(ONE_ONE_QUESTION_COLLECTION)
  
  const [customTitle, setCustomTitle] = useState(null)
  
  const handleSubmit = e => {
    e.preventDefault()
    return questionHooks.addDoc({
      managerRef: userHooks.getDocRef(profile.id),
      oneOneTopicRef: topicHooks.getDocRef(topics.find(t => t.slug === 'custom-questions').id),
      customTitle,
      order,
    })
      .then(ref => getDoc(ref))
      .then(doc => onComplete({ id: doc.id, ...doc.data() }))
      .then(() => setCustomTitle(null))
  }
  
  return (
    <form onSubmit={handleSubmit}>
      <FormControl fullWidth>
        <Input
          type='text'
          onChange={event => setCustomTitle(event.target.value)}
          value={customTitle || ''}
          placeholder='Taper ici...'
          endAdornment={
            <InputAdornment position='end'>
              <IconButton onClick={handleSubmit} disabled={!customTitle} sx={{ color: customTitle && 'primary.main' }}>
                <AddCircleOutline />
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
    </form>
  )
}

NewQuestionForm.propTypes = {
  onComplete: PropTypes.func.isRequired,
  order: PropTypes.number,
}

export default NewQuestionForm
