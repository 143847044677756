import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Typography, FormControlLabel, Box, FormGroup } from '@mui/material'
import GreenCheckbox from '../_library/GreenCheckbox'
import { useTranslation } from 'react-i18next'
import useFirestore from '../../hooks/useFirestore'
import { ONE_ONE_TOPIC_COLLECTION } from '../../_constants/globals'


export const Topics = ({ teammate, selectedTopics, setSelectedTopics }) => {
  
  const topicHooks = useFirestore(ONE_ONE_TOPIC_COLLECTION)
  const topics = topicHooks.getDocs()
  const { t, i18n } = useTranslation()
  
  const labels = useMemo(() => topics?.map(topic => topic.text
    ? topic.text[i18n.language].replace('FIRSTNAME', teammate.firstname)
    : t('one-to-one.noname-category'),
  ), [topics, teammate.firstname, i18n.language])
  
  return (
    <Box sx={{ pt: 2 }}>
      <Typography fontWeight='700'>{t('one-to-one.check-topics')}</Typography>
      <FormGroup>
        {topics?.filter(t => t.slug !== 'custom-questions').map((topic, index) =>
          <FormControlLabel
            key={`filteredTopic${topic.id}`}
            control={<GreenCheckbox name='checkedG' checked={selectedTopics.some(c => c.id === topic.id)} />}
            onChange={event => setSelectedTopics(prev => event.target.checked
              ? topics.filter(q => prev.some(p => p.id === q.id) || topic.id === q.id)
              : prev.filter(c => c.id !== topic.id),
            )}
            label={<Typography fontWeight='normal' lineHeight='1.5rem' my='.7rem'>{labels[index]}</Typography>}
            sx={{ mx: 0 }}
          />,
        )}
      </FormGroup>
    </Box>
  )
}

Topics.propTypes = {
  teammate: PropTypes.object.isRequired,
  selectedTopics: PropTypes.array.isRequired,
  setSelectedTopics: PropTypes.func.isRequired,
}

export default Topics
