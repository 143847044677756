import React from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CTAButton from '../_library/FirstLevelButton'
import { HOME, TEAM } from '../../_constants/routes'
import OutlinedButton from '../_library/OutlinedButton'
import { useNavigate } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'


const OnboardingEndScreen = () => {

  const { t } = useTranslation()
  const profile = useAuth().getProfile()
  const navigate = useNavigate()

  return (
    <Box sx={{ maxWidth: '600px', p: 2, m: '10% auto' }}>
      <Typography variant='h3' fontSize={{ xs: '1.5rem', sm: '1.8rem' }} fontWeight='600'>{t('onboarding.super')}</Typography>
      <Typography variant='h1' mb={3}>{t('onboarding.ready')}</Typography>
      <Typography fontSize='1.2rem'>{t('onboarding.test-paragraph')}</Typography>
      <CTAButton id='generateCardBtn' onClick={() => navigate(HOME)} sx={{ mr: 1, my: 1, width: { xs: '100%', sm: 'inherit' } }}>
        {t('onboarding.explore')}
      </CTAButton>
      {!profile.inviterRef &&
        <OutlinedButton onClick={() => navigate(TEAM)} sx={{ mr: 1, my: 1, width: { xs: '100%', sm: 'inherit' } }}>
          {t('onboarding.invite-members')}
        </OutlinedButton>
      }
    </Box>
  )
}

export default OnboardingEndScreen
