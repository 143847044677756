import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Rating, Box, styled, TextField, Typography } from '@mui/material'
import Balloon, { BACKDROP_TYPE } from '../cards/Balloon'
import OutlinedButton from '../_library/OutlinedButton'
import { StarOutline } from '@mui/icons-material'
import { CARD_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import useAuth from '../../hooks/useAuth'
import useFirestore from '../../hooks/useFirestore'
import { useTranslation } from 'react-i18next'

const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconFilled': {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(2),
    '& svg': {
      fill: theme.palette.primary.main,
      fontSize: '3rem',
    },
    [theme.breakpoints.down('md')]: {
      marginRight: theme.spacing(1),
      '& svg': {
        fontSize: '2rem',
      },
    },
  },
  '& .MuiRating-iconEmpty': {
    marginRight: theme.spacing(2),
    '& svg': {
      fill: 'none',
      stroke: theme.palette.primary.main,
      fontSize: '3rem',
    },
    [theme.breakpoints.down('md')]: {
      marginRight: theme.spacing(1),
      '& svg': {
        fontSize: '2rem',
      },
    },
  },
}))

const COMMENT_MAX_LENGTH = 300

const RatingComponent = ({ card, next }) => {
  
  const profile = useAuth().getProfile()
  const cardHooks = useFirestore(CARD_COLLECTION)
  const userHooks = useFirestore(USER_COLLECTION)
  const { t } = useTranslation()
  
  const [rating, setRating] = useState ( card?.rating || 0)
  const [comment, setComment] = useState (card?.comment || '')
  const [hover, setHover] = useState (-1)
  const [showInfoLowRating, setShowInfoLowRating] = useState (false)
  const [error, setError] = useState ('')

  const handleSubmit = () => {
    setError('')
    if (rating < 1) setError(t('rating.evaluate'))
    else if (rating <= 3 && card.rating !== rating && !showInfoLowRating) setShowInfoLowRating(true)
    else {
      cardHooks.updateDoc(card.id, {
        rating,
        comment,
        read: true,
        readByUserRefs: [userHooks.getDocRef(profile.id)],
      })
      next()
    }
  }

  return (
    <Box sx={{ maxWidth: '100%' }}>
      <Typography variant='h2' mt={6} fontSize='1.5rem' fontWeight={800}>{t('dashboard.rating.title')}</Typography>
      <Typography variant='body1' fontStyle='italic' fontWeight={200} lineHeight='2' mb={2}>{t('dashboard.rating.subtitle')}</Typography>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <StyledRating
          name='customized-color'
          value={rating}
          getLabelText={rating => `${rating} Star${rating !== 1 ? 's' : ''}`}
          precision={1}
          onChange={(event, newValue) => setRating(newValue)}
          onChangeActive={(event, newHover) => setHover (newHover)}
          emptyIcon={<StarOutline />}
        />
        <Box sx={{ fontWeight: 600, color: 'primary.main', ml: 2 }}>
          {t('dashboard.rating.label.' + [hover !== -1 ? hover : rating])}
        </Box>
      </Box>
      <Typography variant='h2' mt={6} fontSize='1.5rem' fontWeight={800}>{t('dashboard.rating.leave-comment')}</Typography>
      <Typography variant='body1' fontStyle='italic' fontWeight={200} lineHeight='2' mb={2}>{t('dashboard.rating.leave-comment-details')}</Typography>
      <TextField
        id='comment-input'
        label={t('dashboard.rating.your-comment')}
        multiline
        rows={4}
        value={comment}
        variant='outlined'
        helperText={COMMENT_MAX_LENGTH - comment.length + ' ' + t('dashboard.rating.remaining-chars')}
        fullWidth
        onChange={e => setComment(e.target.value)}
        error={comment.length > COMMENT_MAX_LENGTH}
        sx={{
          '& .MuiFormHelperText-root': {
            textAlign: 'right',
            marginTop: 0,
          },
        }}
      />
      <OutlinedButton
        onClick={handleSubmit}
        disabled={(card.rating === rating && card.comment === comment) || comment.length > COMMENT_MAX_LENGTH}
      >{t('common.confirm')}</OutlinedButton>
      {error && <Typography color='red'>{error}</Typography>}
      {showInfoLowRating && <Balloon preset='lowRating' onClick={handleSubmit} backdropType={BACKDROP_TYPE.opaqueGreen} />}
    </Box>
  )
}

RatingComponent.propTypes = {
  card: PropTypes.object.isRequired,
  next: PropTypes.func.isRequired,
}

export default RatingComponent
