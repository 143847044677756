import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'
import { TALENT_EXT } from '../../_constants/cardTypes'
import photoIcon from '../../assets/DIVERS/avatar_team_member.svg'
import { getImage } from '../../_helpers/cardHelper'
import useAuth from '../../hooks/useAuth'


const cardStyle = {
  maxWidth: '236px',
  position: { xs: 'relative', md: 'absolute' },
  left: { md: '-14vw' },
  display: { xs: 'flex', md: 'block' },
  width: { xs: '30vw', md: 'initial' },
}

const CardHeader = ({ title, subtitle, type, avatarUrl, backgroundUrl }) => {
  
  const profile = useAuth().getProfile()
  
  const displayedTitle = useMemo(() => profile && title?.replace(/FIRSTNAME/g, profile.firstname), [title, profile])
  
  return (
    <Box sx={{
      bgcolor: { xs: 'background.blue', md: 'primary.dark' },
      width: '100%',
      pt: { xs: '10%', md: 10 },
      pb: { md: 1 },
    }}>
      <Box sx={{
        my: 0,
        mx: { xs: 1, md: 'auto' },
        pl: { xs: 0, md: '11%' },
        textAlign: 'left',
        maxWidth: { xs: '100%', md: 700 },
        position: 'relative',
        color: 'black',
        fontSize: '0.9rem',
        lineHeight: '2rem',
        width: { md: '60vw' },
      }}>
        <Box sx={{
          position: { xs: 'relative', md: 'initial' },
          display: { xs: 'flex', md: 'initial' },
          alignItems: { xs: 'flex-end', md: 'initial' },
        }}>
          {type === TALENT_EXT
            ? <Box sx={cardStyle}>
              <Box component='img' src={getImage(TALENT_EXT)} alt={`card ${TALENT_EXT}`} sx={{ width: '100%' }} />
              <Box sx={{
                top: '28%',
                left: '18%',
                width: '64.5%',
                height: '40%',
                overflow: 'hidden',
                borderRadius: '100px',
                backgroundSize: 'cover',
                position: 'absolute',
                backgroundImage: `url('${avatarUrl || photoIcon}')` }}
              />
            </Box>
            : <Box sx={cardStyle}>
              <Box component='img' src={backgroundUrl || getImage(type)} alt={`card ${type}`} sx={{ width: '100%' }} />
            </Box>
          }
          {subtitle && <Typography variant='h2' color='white' fontSize='1.4rem' fontStyle='italic' mb={0} pl={{ xs: 1, md: 'inherit' }}>{subtitle}</Typography>}
        </Box>
        {title && <Typography variant='h1' color='white' mb={1} fontWeight={600} fontSize={{ xs: '1.8rem', md: '3.5rem' }}>{displayedTitle}</Typography>}
      </Box>
    </Box>
  )
}

CardHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  type: PropTypes.string,
  avatarUrl: PropTypes.string,
  backgroundUrl: PropTypes.string,
}

export default CardHeader
