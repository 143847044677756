import { DEMO_TEAM, TALENT_EXT, TALENT, POSITION, COMMUNICATION, MANAGEMENT, ERROR, TEAM_PROFILE, ONE_TO_ONE_INTRO, ONE_TO_ONE_BUILDER, SHARE_YOUR_NEEDS, PARACHUTE, WINNING_DUO, WORKING_TEAM, ONE_TO_ONE_HOW, ONE_TO_ONE_WHY, ONE_TO_ONE_ADAPT, CAMPFIRE_DUO, CAMPFIRE_TEAM } from '../_constants/cardTypes'
import ingenieuxBadge from '../assets/TEAM_CATEGORY_IMAGES/ingenieux.png'
import audacieuxBadge from '../assets/TEAM_CATEGORY_IMAGES/audacieux.png'
import mcGyverBadge from '../assets/TEAM_CATEGORY_IMAGES/McGyvers.png'
import dirigeantsBadge from '../assets/TEAM_CATEGORY_IMAGES/dirigeants.png'
import inventeurBadge from '../assets/TEAM_CATEGORY_IMAGES/inventeurs.png'
import pionnierBadge from '../assets/TEAM_CATEGORY_IMAGES/pionniers.png'
import artisteBadge from '../assets/TEAM_CATEGORY_IMAGES/artistes.png'
import explorateurBadge from '../assets/TEAM_CATEGORY_IMAGES/explorateurs.png'
import excellenceBadge from '../assets/TEAM_CATEGORY_IMAGES/excellence.png'
import tacticiensBadge from '../assets/TEAM_CATEGORY_IMAGES/tactitiens.png'
import diplomatesBadge from '../assets/TEAM_CATEGORY_IMAGES/diplomates.png'
import familyBadge from '../assets/TEAM_CATEGORY_IMAGES/famille.png'
import humanistesBadge from '../assets/TEAM_CATEGORY_IMAGES/humanistes.png'
import ambassadeurBadge from '../assets/TEAM_CATEGORY_IMAGES/ambassadeurs.png'
import chaleureuxBadge from '../assets/TEAM_CATEGORY_IMAGES/chaleureux.png'
import entraineurBadge from '../assets/TEAM_CATEGORY_IMAGES/entraineurs.png'
import merkel from '../assets/PROFILES/Profiles_Angela Merkel.jpg'
import wintour from '../assets/PROFILES/Profiles_Anna Wintour.jpg'
import obama from '../assets/PROFILES/Profiles_Barack Obama.jpg'
import gates from '../assets/PROFILES/Profiles_Bill Gates.jpg'
import clot from '../assets/PROFILES/Profiles_Christian Clot.jpg'
import eastwood from '../assets/PROFILES/Profiles_Clint Eastwood.jpg'
import lignac from '../assets/PROFILES/Profiles_Cyril Lignac.jpg'
import dion from '../assets/PROFILES/Profiles_Céline Dion.jpg'
import darwin from '../assets/PROFILES/Profiles_Darwin.jpg'
import chopra from '../assets/PROFILES/Profiles_Deepak Chopra.jpg'
import deschamps from '../assets/PROFILES/Profiles_Didier Deschamps.jpg'
import musk from '../assets/PROFILES/Profiles_Elon Musk .jpg'
import gandhi from '../assets/PROFILES/Profiles_Gandhi.jpg'
import lucas from '../assets/PROFILES/Profiles_George Lucas.jpg'
import eiffel from '../assets/PROFILES/Profiles_Gustave Eiffel.jpg'
import goodall from '../assets/PROFILES/Profiles_Jane Goodall.jpg'
import goldman from '../assets/PROFILES/Profiles_Jean-Jacques Goldman.jpg'
import bezos from '../assets/PROFILES/Profiles_Jeff Bezos.jpg'
import rowling from '../assets/PROFILES/Profiles_JK Rowling.jpg'
import tolkien from '../assets/PROFILES/Profiles_JRR Tolkien.jpg'
import harris from '../assets/PROFILES/Profiles_Kamala Harris.jpg'
import simoncini from '../assets/PROFILES/Profiles_Marc Simoncini.jpg'
import luther from '../assets/PROFILES/Profiles_Martin Luther King.jpg'
import scorcese from '../assets/PROFILES/Profiles_Martin Scorcese.jpg'
import teresa from '../assets/PROFILES/Profiles_Mère Térésa.jpg'
import mandela from '../assets/PROFILES/Profiles_Nelson Mandela.jpg'
import chomsky from '../assets/PROFILES/Profiles_Noam Chomsky.jpg'
import sy from '../assets/PROFILES/Profiles_Omar Sy.jpg'
import winfrey from '../assets/PROFILES/Profiles_Oprah Winfrey.jpg'
import francois from '../assets/PROFILES/Profiles_Pape François.jpg'
import elisabeth from '../assets/PROFILES/Profiles_Queen Elisabeth II.jpg'
import nadal from '../assets/PROFILES/Profiles_Rafael Nadal.jpg'
import federer from '../assets/PROFILES/Profiles_Roger Federer.jpg'
import williams from '../assets/PROFILES/Profiles_Serena Williams.jpg'
import sandberg from '../assets/PROFILES/Profiles_Sheryl Sandberg.jpg'
import beauvoir from '../assets/PROFILES/Profiles_Simone de Beauvoir.jpg'
import veil from '../assets/PROFILES/Profiles_Simone Veil.jpg'
import ballmer from '../assets/PROFILES/Profiles_Steve Ballmer.jpg'
import jobs from '../assets/PROFILES/Profiles_Steve Jobs.jpg'
import wozniak from '../assets/PROFILES/Profiles_Steve Wozniak.jpg'
import pesquet from '../assets/PROFILES/Profiles_Thomas Pesquet.jpg'
import ferris from '../assets/PROFILES/Profiles_Tim Ferris.jpg'
import robbins from '../assets/PROFILES/Profiles_Tony Robbins.jpg'
import disney from '../assets/PROFILES/Profiles_Walt Disney.jpg'
import buffet from '../assets/PROFILES/Profiles_Warren Buffet.jpg'
import smith from '../assets/PROFILES/Profiles_Will Smith.jpg'
import churchill from '../assets/PROFILES/Profiles_Winston Churchill.jpg'
import niel from '../assets/PROFILES/Profiles_Xavier Niel.jpg'
import talentImg from '../assets/CARDS/gold_card.png'
import graphImg from '../assets/CARDS/card_positionnement.png'
import communicationImg from '../assets/CARDS/card_communication.png'
import managementImg from '../assets/CARDS/card_management.png'
import lesson1 from '../assets/CARDS/card_lesson1.png'
import teamImg from '../assets/CARDS/card_cartographie.png'
import teammateImg from '../assets/CARDS/card_talent_equipier.png'
import oneToOnePreparingCard from '../assets/CARDS/1on1/preparing1on1.png'
import oneToOneBuilderCard from '../assets/CARDS/1on1/1on1builder.png'
import shareYourNeeds from '../assets/CARDS/KIT1/share_your_needs.png'
import parachute from '../assets/CARDS/KIT1/parachute.png'
import winningDuo from '../assets/CARDS/KIT1/winningDuo.png'
import workingTeam from '../assets/CARDS/KIT1/workingTeam.png'
import oneToOneHow from '../assets/CARDS/how.png'
import oneToOneWhy from '../assets/CARDS/why.png'
import oneToOneAdapt from '../assets/CARDS/adapt.png'
import ghost from '../assets/CARDS/rectangle.svg'
import campfireDuo from '../assets/CARDS/campfire_duo.png'
import campfireTeam from '../assets/CARDS/campfire_team.png'


export const getTeamProfileType = teamMembers => {
  if (!teamMembers || !teamMembers[0]) return
  const result = teamMembers
    .reduce(
      (acc, val) => ({ testResults: { X: acc.testResults.X + val.testResults.X, Y: acc.testResults.Y + val.testResults.Y } } ),
      { testResults: { X: 0, Y: 0 } },
    )
  const X = result.testResults.X / teamMembers.length
  const Y = result.testResults.Y / teamMembers.length
  const absX = Math.abs(X)
  const absY = Math.abs(Y)
  let first, second
  if (X > 0) {
    if (Y > 0) {
      first = 'C'
      second = absX > absY ? 'M' : 'A'
    } else {
      first = 'M'
      second = absX > absY ? 'C' : 'F'
    }
  } else {
    if (Y > 0) {
      first = 'A'
      second = absX > absY ? 'F' : 'C'
    } else {
      first = 'F'
      second = absX > absY ? 'A' : 'M'
    }
  }
  const third = teamMembers.filter(el => el.profileType.split('')[2] === 'I').length > teamMembers.length / 2 ? 'I' : 'E'
  return first + second + third
}

export const getTeamTitle = profileType => {
  switch (profileType) {
    case 'ACI': return 'Les ingénieux'
    case 'ACE': return 'Les audacieux'
    case 'AFI': return 'Les MacGyvers'
    case 'AFE': return 'Les dirigeants'
    case 'CAI':	return 'Les inventeurs'
    case 'CAE':	return 'Les pionniers'
    case 'CMI':	return 'Les artistes'
    case 'CME':	return 'Les explorateurs'
    case 'FAI':	return 'Les artisans d\'excellence'
    case 'FAE':	return 'Les tacticiens'
    case 'FMI':	return 'Les diplomates'
    case 'FME':	return 'La famille'
    case 'MCI':	return 'Les humanistes'
    case 'MCE':	return 'Les ambassadeurs'
    case 'MFI':	return 'Les chaleureux'
    case 'MFE':	return 'Les entraîneurs'
    default: throw Error('Unknown profile')
  }
}

export const getTeamBadge = profileType => {
  switch (profileType) {
    case 'ACI': return ingenieuxBadge
    case 'ACE': return audacieuxBadge
    case 'AFI': return mcGyverBadge
    case 'AFE': return dirigeantsBadge
    case 'CAI':	return inventeurBadge
    case 'CAE':	return pionnierBadge
    case 'CMI':	return artisteBadge
    case 'CME':	return explorateurBadge
    case 'FAI':	return excellenceBadge
    case 'FAE':	return tacticiensBadge
    case 'FMI':	return diplomatesBadge
    case 'FME':	return familyBadge
    case 'MCI':	return humanistesBadge
    case 'MCE':	return ambassadeurBadge
    case 'MFI':	return chaleureuxBadge
    case 'MFE':	return entraineurBadge
    default: throw Error('Unknown profile')
  }
}

export const getFamousPeople = profileType => {
  switch (profileType) {
    case 'CMI':	return [{ slug: 'chomsky', name: 'Noam Chomsky', avatar: chomsky }, { slug: 'goodall', name: 'Jane Goodall', avatar: goodall }, { slug: 'gandhi', name: 'Gandhi', avatar: gandhi }]
    case 'CAI':	return [{ slug: 'jobs', name: 'Steve Jobs', avatar: jobs }, { slug: 'musk', name: 'Elon Musk ', avatar: musk }, { slug: 'beauvoir', name: 'Simone de Beauvoir', avatar: beauvoir }]
    case 'MCI':	return [{ slug: 'tolkien', name: 'JRR Tolkien', avatar: tolkien }, { slug: 'lucas', name: 'George Lucas', avatar: lucas }, { slug: 'rowling', name: 'JK Rowling', avatar: rowling }]
    case 'MFI':	return [{ slug: 'mandela', name: 'Nelson Mandela', avatar: mandela }, { slug: 'pesquet', name: 'Thomas Pesquet', avatar: pesquet }, { slug: 'goldman', name: 'Jean-Jacques Goldman', avatar: goldman }]
    case 'FMI':	return [{ slug: 'elisabeth', name: 'Queen Elisabeth II', avatar: elisabeth }, { slug: 'deschamps', name: 'Didier Deschamps', avatar: deschamps }, { slug: 'teresa', name: 'Mère Térésa', avatar: teresa }]
    case 'FAI':	return [{ slug: 'merkel', name: 'Angela Merkel', avatar: merkel }, { slug: 'buffet', name: 'Warren Buffet', avatar: buffet }, { slug: 'nadal', name: 'Rafael Nadal', avatar: nadal }]
    case 'AFI': return [{ slug: 'eastwood', name: 'Clint Eastwood', avatar: eastwood }, { slug: 'federer', name: 'Roger Federer', avatar: federer }, { slug: 'niel', name: 'Xavier Niel', avatar: niel }]
    case 'ACI': return [{ slug: 'bezos', name: 'Jeff Bezos', avatar: bezos }, { slug: 'darwin', name: 'Darwin', avatar: darwin }, { slug: 'gates', name: 'Bill Gates', avatar: gates }]
    case 'CME':	return [{ slug: 'obama', name: 'Barack Obama', avatar: obama }, { slug: 'disney', name: 'Walt Disney', avatar: disney }, { slug: 'simoncini', name: 'Marc Simoncini', avatar: simoncini }]
    case 'CAE':	return [{ slug: 'scorcese', name: 'Martin Scorsese', avatar: scorcese }, { slug: 'veil', name: 'Simone Veil', avatar: veil }, { slug: 'wozniak', name: 'Steve Wozniak', avatar: wozniak }]
    case 'MCE':	return [{ slug: 'winfrey', name: 'Oprah Winfrey', avatar: winfrey }, { slug: 'luther', name: 'Martin Luther King', avatar: luther }, { slug: 'robbins', name: 'Tony Robbins', avatar: robbins }]
    case 'MFE':	return [{ slug: 'francois', name: 'Pape François', avatar: francois }, { slug: 'chopra', name: 'Deepak Chopra', avatar: chopra }, { slug: 'lignac', name: 'Cyril Lignac', avatar: lignac }]
    case 'FME':	return [{ slug: 'smith', name: 'Will Smith', avatar: smith }, { slug: 'williams', name: 'Serena Williams', avatar: williams }, { slug: 'sy', name: 'Omar Sy', avatar: sy }]
    case 'FAE':	return [{ slug: 'churchill', name: 'Winston Churchill', avatar: churchill }, { slug: 'clot', name: 'Christian Clot', avatar: clot }, { slug: 'wintour', name: 'Anna Wintour', avatar: wintour }]
    case 'AFE': return [{ slug: 'harris', name: 'Kamala Harris', avatar: harris }, { slug: 'dion', name: 'Céline Dion', avatar: dion }, { slug: 'ballmer', name: 'Steve Ballmer', avatar: ballmer }]
    case 'ACE': return [{ slug: 'eiffel', name: 'Gustave Eiffel', avatar: eiffel }, { slug: 'sandberg', name: 'Sheryl Sandberg', avatar: sandberg }, { slug: 'ferris', name: 'Tim Ferris', avatar: ferris }]
    default: throw Error('Unknown profile')
  }
}

export const getImage = type => {
  switch (type) {
    case TALENT:
      return talentImg
    case POSITION:
      return graphImg
    case COMMUNICATION:
      return communicationImg
    case MANAGEMENT:
      return managementImg
    case ERROR:
      return lesson1
    case TEAM_PROFILE:
      return teamImg
    case TALENT_EXT:
      return teammateImg
    case DEMO_TEAM:
      return teamImg
    case ONE_TO_ONE_INTRO:
      return oneToOnePreparingCard
    case ONE_TO_ONE_BUILDER:
      return oneToOneBuilderCard
    case SHARE_YOUR_NEEDS:
      return shareYourNeeds
    case PARACHUTE:
      return parachute
    case WINNING_DUO:
      return winningDuo
    case WORKING_TEAM:
      return workingTeam
    case ONE_TO_ONE_HOW:
      return oneToOneHow
    case ONE_TO_ONE_WHY:
      return oneToOneWhy
    case ONE_TO_ONE_ADAPT:
      return oneToOneAdapt
    case CAMPFIRE_DUO:
      return campfireDuo
    case CAMPFIRE_TEAM:
      return campfireTeam
    default:
      return ghost
  }
}

export const isRead = (card, uid) => card.type === TALENT_EXT
  ? card.readByUserRefs?.some(ref => ref.id === uid)
  : !!card.read
