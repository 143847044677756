import { DEMO_TEAM, TALENT_EXT } from '../_constants/cardTypes'
import { useEffect, useMemo } from 'react'
import useFirestore from './useFirestore'
import { CAMPFIRE_COLLECTION, CARD_COLLECTION, CARD_TEMPLATE_COLLECTION, TEAM_COLLECTION, USER_COLLECTION } from '../_constants/globals'
import useAuth from './useAuth'
import { useSelector } from 'react-redux'
import { arrayUnion } from 'firebase/firestore'
import { findIndex } from 'lodash'
import { useTranslation } from 'react-i18next'


const useCards = () => {
  
  const profile = useAuth().getProfile()
  const cardHooks = useFirestore(CARD_COLLECTION)
  const dbCards = cardHooks.getDocs()
  const userHooks = useFirestore(USER_COLLECTION)
  const consultantMode = useSelector(state => state.data.consultantMode)
  const selectedUserId = useSelector(state => state.data.selectedUserId)
  const templateHooks = useFirestore(CARD_TEMPLATE_COLLECTION)
  const dbTemplates = templateHooks.getDocs()
  const teamHooks = useFirestore(TEAM_COLLECTION)
  const dbTeams = teamHooks.getDocs()
  const campfireHooks = useFirestore(CAMPFIRE_COLLECTION)
  const dbCampfires = campfireHooks.getDocs()
  const { t, i18n } = useTranslation()
  
  const cards = useMemo(
    () => dbCards.filter(c => {
      if (selectedUserId && consultantMode)
        return c.userRef.id === selectedUserId
      else if (profile.companyRef)
        return c.userRef.id === profile.id || (c.type === 'talent-ext' && c.companyRef.id === profile.companyRef.id)
      else
        return c.userRef.id === profile.id
    }),
    [dbCards, profile.id, selectedUserId],
  )
  const teamProfile = useMemo(() => dbTemplates?.find(t => t.type === 'team-profile'), [dbTemplates])
  const team = useMemo(() => dbTeams?.find(t => t.id === profile.currentTeamSelectedRef?.id), [dbTeams, profile.currentTeamSelectedRef])
  
  // Listen cards
  useEffect(() => {
    if (profile.id) {
      let whereCond
      if (consultantMode && selectedUserId) whereCond = [['userRef', '==', userHooks.getDocRef(selectedUserId)]]
      else if (profile.companyRef) whereCond = [['companyRef', '==', profile.companyRef]]
      else whereCond = [['userRef', '==', userHooks.getDocRef(profile.id)]]
      cardHooks.listen({
        where: whereCond,
        orderBy: [['order', 'asc']],
      })
      return cardHooks.unsubscribe
    }
  }, [profile.id, profile.profileType, consultantMode, selectedUserId])
  
  // Listen campfires
  useEffect(() => {
    if (profile.companyRef) {
      campfireHooks.listen({
        where: [['companyRef', '==', profile.companyRef]],
        orderBy: [['_createdAtTime', 'asc']],
      })
      return campfireHooks.unsubscribe
    }
  }, [profile.companyRef])
  
  const aboutYouCards = useMemo(
    () => cards?.filter(card => card.section === 'about-you' && card.userRef.id === profile.id),
    [cards, profile.id],
  )
  
  const aboutTeamCards = useMemo(() => {
    
    const sorteds = []
    
    // The first card to show is the one of the connected user
    if (cards && cards.some(card => card.section === 'about-team' && card.userRef.id === profile.id))
      sorteds.push(cards.find(card => card.section === 'about-team' && card.userRef.id === profile.id))
    
    // Then display team card
    // In the case where user is not attached to a company, demo card will be displayed
    if (sorteds) {
      profile.companyRef
        ? sorteds.push({
          type: teamProfile?.type,
          title: teamProfile?.subtitle[i18n.language],
          readByUserRefs: team?.teamCardReadByUserRefs,
        })
        : sorteds.push({ type: DEMO_TEAM, title: t('dashboard.team-card.example') })
    }
    
    // Add all team members cards
    if (cards)
      sorteds.push(...cards.filter(card => card.section === 'about-team' && card.userRef.id !== profile.id && !!card.rating && team?.memberRefs.some(ref => ref.id === card.userRef.id)))
    
    // Animate first card not read and set ghost mode to others
    let cardToRead = false
    for (let i = 0; i < sorteds.length; i++) {
      const notReadYet = !sorteds[i].readByUserRefs || !sorteds[i].readByUserRefs.some(element => element.id === profile.id)
      if (cardToRead && notReadYet)
        sorteds[i].isGhost = true
      else if ((notReadYet || (sorteds[i].type === TALENT_EXT && sorteds[i].user.id === profile.id && !sorteds[i].rating))) {
        sorteds[i].isAnimated = true
        sorteds[i].isGhost = false
        cardToRead = true
      }
    }
    return sorteds
  }, [cards, teamProfile, team, profile ])
  
  const campfireCards = useMemo(
    () => dbCampfires?.filter(c => c.teamRef.id === profile.currentTeamSelectedRef?.id)
      .map(c => ({
        ...c,
        available: c.status === 'actif',
        subtitle: 'dashboard.card-subtitle.campfire-' + c.type,
      })),
    [dbCampfires, profile.currentTeamSelectedRef],
  )
  
  const oneToOneCards = useMemo(() =>
    [{ type: 'one-to-one-builder', subtitle: 'Préparez vos feux de camp en duo', id: 'builder', notReadable: true }], [])
  
  const readCard = id => {
    if (consultantMode) return
    console.info('READ CARD', id)
    const card = cards.find(c => c.id === id)
    return card.type === TALENT_EXT && card.userRef.id !== profile.id
      ? cardHooks.updateDoc(id, { readByUserRefs: arrayUnion(userHooks.getDocRef(profile.id)) })
      : cardHooks.updateDoc(id, { read: window.location.origin })
  }
  
  const drawNextCard = id => {
    if (consultantMode) return
    console.info('DRAW NEXT CARD', id)
    const cardIndex = findIndex(cards, c => c.id === id)
    const nextCard = cards[cardIndex + 1]
    console.info('nextCard', nextCard)
    if (nextCard && nextCard.type !== TALENT_EXT)
      return cardHooks.updateDoc(nextCard.id, { available: true })
  }
  
  return { aboutYouCards, aboutTeamCards, campfireCards, oneToOneCards, cards, readCard, drawNextCard }
}

export default useCards
