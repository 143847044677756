import React, { useEffect, useMemo, useState } from 'react'
import { Box, TextField, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CTAButton from '../_library/FirstLevelButton'
import { useDispatch } from 'react-redux'
import { useMatch } from 'react-router-dom'
import { PAYMENT_SUMMARY } from '../../_constants/routes'
import { COMPANY_COLLECTION, PAYMENT_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import useAuth from '../../hooks/useAuth'
import { ERROR } from '../../store/types'
import useFirestore from '../../hooks/useFirestore'


const CompanyUpdateForm = () => {
  
  const { t } = useTranslation()
  const profile = useAuth().getProfile()
  const companyHooks = useFirestore(COMPANY_COLLECTION)
  const dbCompanies = companyHooks.getDocs()
  const dispatch = useDispatch()
  const match = useMatch(PAYMENT_SUMMARY)
  const paymentHooks = useFirestore(PAYMENT_COLLECTION)
  const payments = paymentHooks.getDocs()
  const userHooks = useFirestore(USER_COLLECTION)
  
  const [name, setName] = useState(null)
  const [street, setStreet] = useState(null)
  const [zipcode, setZipcode] = useState(null)
  const [city, setCity] = useState(null)
  const [submitted, setSubmitted] = useState(false)
  const [error, setError] = useState(null)
  
  const company = useMemo(() => dbCompanies ? dbCompanies[0] : null, [dbCompanies])
  
  useEffect(() => {
    if (!company) return
    setName(company.name)
    setStreet(company.address?.street)
    setCity(company.address?.city)
    setZipcode(company.address?.zipcode)
  }, [company])
  
  useEffect(() => {
    if (payments) {
      const { session, error } = payments[0]
      console.log('session', session)
      if (session) {
        // We have a session, let's redirect to Checkout
        const stripe = window.Stripe(process.env.REACT_APP_STRIPE_PK)
        stripe.redirectToCheckout({ sessionId: session.id })
      }
      else if (error) {
        console.error(error.message)
        dispatch({ type: ERROR, payload: error.message } )
      }
    }
  }, [payments])
  
  const handleSubmit = async event => {
    event.preventDefault()
    if (!profile.email) return setError('Merci de réessayer dans un instant')
    if (!street || !zipcode || !city) return setError('Merci de remplir tous les champs')
    setSubmitted(true)
    
    const companyData = { name, address: { street, zipcode, city } }
    if (profile.companyRef) await companyHooks.updateDoc(profile.companyRef.id, companyData)
    else {
      const companyRef = await companyHooks.addDoc(companyData)
      await userHooks.updateDoc(profile.id, { accessLevel: 75, companyRef })
    }
    
    return paymentHooks.addDoc({
      userRef: userHooks.getDocRef(profile.id),
      companyRef: profile.companyRef,
      quantity: Number(match.params.number),
      invoice: { name, street, zipcode, city },
    })
      .then(ref => paymentHooks.listenDoc(ref.id))
  }
  
  const textFieldStyle = {
    display: 'block',
    py: 2,
    '& .MuiInput-root': { mt: 1 },
  }
  
  return (
    <Box component='form' onSubmit={handleSubmit} sx={{ maxWidth: { xs: '100%', md: '350px' } }}>
      <div>
        {submitted && <Typography variant='h3'>En attente de redirection...</Typography>}
        {!submitted && <div>
          <TextField
            onChange={e => setName(e.target.value )}
            name='name'
            type='text'
            label={t('common.company-name')}
            value={name || ''}
            color='secondary'
            fullWidth
            sx={textFieldStyle}
          />
          <TextField
            onChange={e => setStreet(e.target.value )}
            name='street'
            type='text'
            label={t('common.billing-address')}
            value={street || ''}
            color='secondary'
            fullWidth
            sx={textFieldStyle}
          />
          <TextField
            onChange={e => setCity(e.target.value )}
            name='city'
            type='text'
            label={t('common.city')}
            value={city || ''}
            color='secondary'
            fullWidth
            sx={textFieldStyle}
          />
          <TextField
            onChange={e => setZipcode(e.target.value )}
            name='zipCode'
            type='text'
            label={t('common.zipcode')}
            value={zipcode || ''}
            color='secondary'
            fullWidth
            sx={textFieldStyle}
          />
          <CTAButton type='submit' sx={{ mt: 2 }}>{t('pricing.go')}</CTAButton>
          {error && <Typography style={{ color: 'red' }}>{error}</Typography>}
        </div>}
      </div>
    </Box>
  )
}

export default CompanyUpdateForm
